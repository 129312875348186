<!-- @format -->

<template>
  <div>
    <iframe
      id="ytVideo"
      :style="`margin: ${margin}px`"
      :height="height"
      :width="width"
      :src="url"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
  export default {
    props: {
      url: {
        type: String,
        default: '',
      },
      margin: {
        type: Number,
        default: 0,
      },
      height: {
        type: Number,
        default: 0,
      },
      width: {
        type: Number,
        default: 0,
      },
    },
  }
</script>
